import React from 'react'
import Layout from '../components/layout'
import styled from 'styled-components'

export default () => (
    <Layout>
        <StyledSection>
            <p>kontakt@piotrbaran.it</p>
            <p>(+48) 608788223</p>
        </StyledSection>
    </Layout>
)

const StyledSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2rem;
`